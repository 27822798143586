import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row, Button, Loading, IconButton, MailIcon, colors } from '@commonsku/styles';
import { useHasCapabilities, useIdentity } from "../../hooks";
import withReducers from "../../store/withReducers";
import companyGeneralDataReducer from "../../redux/companyGeneralData";
import config from '../../config';

const  ZapierIntegration = ({ paywallCtaUrl }) => {
  const identity = useIdentity();
  const isZapierEnabled = useHasCapabilities(['CONNECT-ZAPIER']) && !isEmpty(config?.zapier?.embed_client_id);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "module";
    script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
    document.head.appendChild(link);
  }, []);

  return !scriptLoaded ? <Loading /> : <>{
    isZapierEnabled
    ? <zapier-workflow
      sign-up-email={identity.login_email}
      sign-up-first-name={identity.user_first_name}
      sign-up-last-name={identity.user_last_name}
      client-id={config?.zapier?.embed_client_id}
      theme="light"
      intro-copy-display="show"
      manage-zaps-display="hide"
      guess-zap-display="hide"
      app-search-bar-display="show"
    />
    : <Row>
      <div style={{
        display: 'flex', background: colors.primary1['20'], marginLeft: '20px', marginRight: '20px', padding: '20px'}}
      >
        <div>
          <h4>Upgrade to Advanced to integrate with Zapier</h4>
          <p>Advanced Administrators can configure and connect their webhooks and APIs through the commonsku admin settings.</p>
          <p>This allows for integration into 3rd party applications that are not existing integrations with commonsku.</p>
        </div>
        <div style={{marginLeft: '25px'}}>
          <Button
            variant="secondary" size={'large'} style={{width: '186px'}}
            onClick={() => { window.location.href = paywallCtaUrl; }}
          >Learn More</Button>
          <IconButton
            variant="error" size={'large'} Icon={MailIcon} style={{marginTop: '10px', width: '186px'}}
            onClick={() => {
              window.location.href = 'mailto:support@commonsku.com';
            }}
          >Contact Us</IconButton>
        </div>
      </div>
    </Row>
  }</>;
};

export default withReducers(ZapierIntegration, {
    companyGeneralDataById: companyGeneralDataReducer,
});
